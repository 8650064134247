import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

class Content extends Component {
  render() {
    return (
      <div>
        <Container style={{ padding: '100px 0' }}>
          <h3>About:</h3>
          <br />
          <br />
          <p>
            My love for computers and coding began long before I can remember. I think the earliest
            memory I have of using a computer is playing Roller Coaster Tycoon as a kid. It wasn't
            long before I went from making roller coasters into making software.
          </p>

          <p>
            In addition to writing and debugging code, I also love music and play the drums, the
            guitar and piano. In my free time you'll find me either shredding on the ski slopes or
            zooming on my Ducati.
          </p>
        </Container>
      </div>
    );
  }
}

export default Content;
