import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Typer from './Typer';

class Landing extends Component {
  render() {
    return (
      <div>
        <Container fluid="xxl">
          <Row className="align-items-center vh-100">
            <Col>
              <div className="display-1 fw-lighter fs-1 text-uppercase">Cannon Farr</div>
              <Typer
                strings={[
                  'Student',
                  'Coder',
                  'Motorcyclist',
                  'Drummer',
                  'Learner',
                  'Skiier',
                  'Musician',
                  'Guitarist',
                  'Developer',
                  'Leader',
                  'Star Wars Enthusiast',
                ]}
              />
              <Row></Row>
              <div>
                First-year computer science student, twenty-first year learner. I'm from Alpine,
                Utah currently attending BYU. I am a full-stack developer currently working at a
                research lab at BYU.
              </div>
            </Col>
            <Col>
              <div>
                <img
                  src="images/Landing_BW.png"
                  width="125%"
                  // height="100vh"
                  style={{ height: '100vh', objectFit: 'cover' , objectPosition: '0 15%'}}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Landing;
