import './app.scss';
import Base from './components/Base';

function App() {
  return (
    <div>
      <Base />
    </div>
  );
}

export default App;
