import React, { Component } from 'react';

import Landing from './Landing';
import Header from './Header';
import Footer from './Footer';
import Content from './Content';

class Base extends Component {
  constructor(props) {
    super(props);
    this.state = { hideFooter: true };
  }

  

  render() {
    return (
      <div>
        <Header />
        <Landing />
        <Content />
        <Footer />
      </div>
    );
  }
}

export default Base;
